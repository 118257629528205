import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import remarkGfm from "remark-gfm";

export function ResponseResult({ result }) {
  if (result.value) {
    return (
      <div>
        {result.value.map((item, idx) => (
          <div key={idx}>{item}</div>
        ))}
      </div>
    );
  }
  return <ReactMarkdown remarkPlugins={[remarkGfm]}>{result}</ReactMarkdown>;
}
