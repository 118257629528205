export function PromptEngineeringResponse({ response }) {
  return (
    <>
      {response && (
        <div className="bd-callout bd-callout-info text-wrap">
          <pre style={{ whiteSpace: "pre-wrap" }}>
            {JSON.stringify(response, null, 2)}
          </pre>
        </div>
      )}
    </>
  );
}
