import { Container } from "react-bootstrap";
import Header from "../../components/Header";
import { Link } from "react-router-dom";

export default function Pricing() {
  return (
    <>
      <Header>
        <h1>
          <Link to="/" className="text-decoration-none text-light">
            Prompt Engineering
          </Link>
        </h1>
      </Header>
      <Container>
        <h2>Pricing</h2>
        <p>
          Current is beta free, enjoy all feature or contact us to request
          features request features.
        </p>
        <div className="text-center my-5">
          <Link to="/" className="btn btn-outline-primary">
            Home
          </Link>
        </div>
      </Container>
    </>
  );
}
