import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <button
      className="btn btn-outline-dark"
      onClick={() =>
        loginWithRedirect({
          appState: {
            returnTo: `${window.location.origin}/prompt-engineering/#/models-manager`,
          },
        })
      }
    >
      Log In
    </button>
  );
};

export default LoginButton;
