import { useState } from "react";
import BasicChat from "../BasicChat/BasicChat";
import OpenAIService from "../../../services/OpenAIService";
import openAIModels from "../../../data/openai-models.json";
import ChatSuggestionCard from "./ChatSuggestionCard";

const openAIService = new OpenAIService();

export default function ChatSuggestion() {
  const [chatlog, setChatlog] = useState([
    {
      role: "assistant",
      content: "Hello, I am a chatbot. How can I help you?",
    },
  ]);
  const [status, setStatus] = useState("idle");
  const [content, setContent] = useState("");

  const handleSendMessage = (e) => {
    e.preventDefault();
    const newChatlog = [...chatlog, { role: "user", content }];
    setChatlog(newChatlog);
    setStatus("pending");
    console.log("send message");
    openAIService
      .run({
        messages: newChatlog,
        model: openAIModels.data.find((model) => model.id === "gpt-4"),
      })
      .then((rsp) => {
        console.log("rsp", rsp);
        const { result } = rsp;
        console.log("result", result);
        setChatlog((chatlog) => [
          ...chatlog,
          { role: "assistant", content: rsp.choices[0].message.content },
        ]);
        setStatus("successed");
      })
      .catch((err) => {
        console.log("err", err);
        setStatus("failed");
      });

    setContent("");
  };

  return (
    <div className="container">
      <h1>Chat Suggestion</h1>
      <div className="row">
        <div className="col-9">
          <BasicChat
            onSubmit={handleSendMessage}
            chatlog={chatlog}
            onChange={(e) => setContent(e.target.value)}
            inputValue={content}
            status={status}
          />
        </div>
        <div className="col-3">
          <ChatSuggestionCard chatlog={chatlog} chatStatus={status} />
        </div>
      </div>
    </div>
  );
}
