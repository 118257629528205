import { useAuth0 } from "@auth0/auth0-react";
import ModelsManager from "./ModelsManager";
import LoginButton from "../../components/Auth0/LoginButton";
import LoginScreen from "../../components/Auth0/LoginScreen";

export default function ModelsManagerIndex() {
  const { user, isAuthenticated, isLoading } = useAuth0();
  console.log("isLoading", isLoading, isAuthenticated);

  if (isLoading) {
    return (
      <div className="m-5">
        <LoginButton />
      </div>
    );
  }

  return isAuthenticated ? <ModelsManager /> : <LoginScreen />;
}
