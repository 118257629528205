import HRNumbers from "human-readable-numbers";
import { useEffect, useState } from "react";

export default function IndexedDBSotrage() {
  const [storageInfo, setStorageInfo] = useState();

  useEffect(() => {
    navigator.storage.estimate().then((estimate) => {
      console.log("Storage estimate", estimate);
      setStorageInfo(estimate);
    });
  }, []);

  if (!storageInfo) return null;
  var remSpace =
    storageInfo.quota * 0.8 -
    unescape(encodeURIComponent(JSON.stringify(indexedDB))).length;
  return (
    <div>
      <label title="Session storage will clean up when you close your browser.">
        Indexed
      </label>
      : {HRNumbers.toHumanString(storageInfo?.usageDetails.indexedDB)} /{" "}
      {HRNumbers.toHumanString(storageInfo?.quota * 0.8)}
    </div>
  );
}
