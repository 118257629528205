import { useAuth0 } from "@auth0/auth0-react";
import LoginButton from "./LoginButton";
import { useEffect } from "react";

export default function LoginScreen() {
  const { isAuthenticated, logout, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (!isAuthenticated) {
      loginWithRedirect();
    }
  }, []);
  return (
    <>
      <LoginButton />
    </>
  );
}
