import Chat from "./Chat";
import TextCompletions from "./Completions";

class OpenAIService {
  constructor() {
    this.chat = new Chat();
    this.completions = new TextCompletions();
  }

  async run({ prompt, messages, model }) {
    console.info("[OpenAIService](run)");
    if (messages) {
      const rsp = this.chat.completions.create({ messages, model });
      return rsp;
    } else if (prompt) {
      const rsp = this.completions.create({ prompt, model });
      return rsp;
    } else {
      throw new Error("prompt or messages should be provided");
    }
  }
}

export default OpenAIService;
