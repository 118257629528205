import openAIdeprecatedModels from "../../../services/OpenAIService/data/deprecatedModels";

export default function useDeprecatedModel(model) {
  return openAIdeprecatedModels.find(
    (m) => model.owned_by && model.id === m.model
  );
}

export function getDeprcatedModel(model) {
  return openAIdeprecatedModels.find(
    (m) => model.owned_by && model.id === m.model
  );
}
