import { createSlice } from "@reduxjs/toolkit";
import papa from "papaparse";
import {
  dutyCellThunk,
  dutyEstimationThunk,
  harmonizedCodeThunk,
  legalComplianceTempThunk,
  legalComplianceThunk,
  sansactionCheckThunk,
} from "./TradinAIThunks";
import {
  dutyCellFulfilledReducer,
  dutyCellPendingReducer,
  dutyCellRejectedReducer,
} from "./TradinAIReducers";

const TradinAISlice = createSlice({
  name: "tradinAI",
  initialState: {
    productData: [],
    sansactionCheck: {
      description:
        "checking the validity of the transaction based on company name and location",
      status: "idle",
      result: null,
    },
    harmonizedCode: {
      description: "predict the harmonized code based on product description",
      status: "idle",
      result: null,
    },
    legalCompliance: {
      description: "checking the legal compliance based on the CBP database",
      status: "idle",
      result: {},
    },
    dutyEstimation: {
      description:
        "estimating the duty based on the product description and manufacture origin",
      status: "idle",
      result: {},
    },
    dutyCell: {},
  },
  reducers: {
    setProductData: (state, action) => {
      state.productData = papa.parse(action.payload, { header: true }).data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sansactionCheckThunk.pending, (state) => {
      state.sansactionCheck.status = "pending";
    });
    builder.addCase(sansactionCheckThunk.fulfilled, (state, action) => {
      state.sansactionCheck.status = "idle";
      state.sansactionCheck.result = action.payload;
    });
    builder.addCase(sansactionCheckThunk.rejected, (state, action) => {
      state.error = action.payload;
      console.error("sansactionCheck.rejected", action.payload);
    });
    builder.addCase(harmonizedCodeThunk.pending, (state) => {
      state.harmonizedCode.status = "pending";
    });
    builder.addCase(harmonizedCodeThunk.fulfilled, (state, action) => {
      console.log("harmonizedCodeThunk.fulfilled", action);
      state.harmonizedCode.status = "idle";
      state.harmonizedCode.result =
        action.payload.source === "google"
          ? action.payload.responce.candidates[0].output
          : action.payload.responce.rsp.choices[0].message.content;
    });
    builder.addCase(harmonizedCodeThunk.rejected, (state, action) => {
      state.error = action.payload;
      console.error("harmonizedCode.rejected", action.payload);
    });
    builder.addCase(legalComplianceThunk.pending, (state, action) => {
      state.legalCompliance.status = "pending";
      state.dutyCell[action.meta.arg.product["Device Name"]] = {};
    });
    builder.addCase(legalComplianceThunk.fulfilled, (state, action) => {
      state.legalCompliance.status = "idle";
      state.legalCompliance.results = action.payload;
    });
    builder.addCase(legalComplianceThunk.rejected, (state, action) => {
      state.error = action.payload;
      console.error("legalCompliance.rejected", action.payload);
    });
    builder.addCase(legalComplianceTempThunk.pending, (state, action) => {
      state.legalCompliance.status = "pending";
      state.dutyCell[action.meta.arg.product["Device Name"]] = {};
    });
    builder.addCase(legalComplianceTempThunk.fulfilled, (state, action) => {
      console.log("legalComplianceTempThunk.fulfilled", action);
      const { product, responce } = action.payload;
      console.log("legalComplianceTempThunk.fulfilled product", product);
      state.legalCompliance.status = "success";
      try {
        state.legalCompliance.result[product["Device Name"]] =
          action.payload.source === "google"
            ? responce.candidates[0].content
            : responce.rsp.choices[0].message.content;
      } catch (e) {
        console.error("product not founded", product);
      }
    });
    builder.addCase(legalComplianceTempThunk.rejected, (state, action) => {
      console.error("legalComplianceTempThunk.rejected", action);
      state.error = action.payload;
      console.error("legalCompliance.rejected", action.payload);
    });
    builder.addCase(dutyEstimationThunk.pending, (state) => {
      state.dutyEstimation.status = "pending";
    });
    builder.addCase(dutyEstimationThunk.fulfilled, (state, action) => {
      state.dutyEstimation.status = "success";
      const { product, responce } = action.payload;
      state.dutyEstimation.result[product["Device Name"]] =
        responce.candidates[0].content;
    });
    builder.addCase(dutyEstimationThunk.rejected, (state, action) => {
      state.error = action.payload;
      console.error("dutyEstimation.rejected", action.payload);
    });
    builder.addCase(dutyCellThunk.pending, dutyCellPendingReducer);
    builder.addCase(dutyCellThunk.fulfilled, dutyCellFulfilledReducer);
    builder.addCase(dutyCellThunk.rejected, dutyCellRejectedReducer);
  },
});

export const { setProductData } = TradinAISlice.actions;

export default TradinAISlice;
