import BasicChatInput from "./BasicChatInput";
import BasicChatLog from "./BasicChatlog";

/**
 * @description this is a shared chat component support basic chat function
 */
export default function BasicChat({
  chatlog,
  onSubmit,
  onChange,
  inputValue,
  status,
}) {
  return (
    <div>
      <form
        onSubmit={onSubmit}
        style={{ minHeight: 500 }}
        className="d-flex align-items-start  flex-column"
      >
        <div className="mb-auto">
          <BasicChatLog chatlog={chatlog} />
          {status === "pending" && (
            <div className="d-flex justify-content-center">
              <div className="spinner-border text-muted" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
        </div>
        <div className="align-items-end w-100">
          <BasicChatInput onChange={onChange} value={inputValue} />
        </div>
      </form>
    </div>
  );
}
