export default class Chat {
  constructor() {
    this.completions = new ChatCompletions();
  }
}

class ChatCompletions {
  async create({ messages, model }) {
    console.info("[ChatCompletion](create)");
    console.log("[ChatCompletion](create) messages, model", messages, model);
    const rsp = await fetch(
      "https://finai-server.deno.dev/openai/v1/chat/completions",
      {
        method: "POST",
        body: JSON.stringify({ messages, model }),
      }
    ).then((rsp) => rsp.json());
    return rsp;
  }
}
