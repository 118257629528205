import { useDispatch, useSelector } from "react-redux";
import {
  sendPrompt,
  updateCurrentModel,
} from "../../../../store/PromptEngingeeringSlice/PromptEngineeringSlice";
import { useEffect, useState } from "react";
import { ResponseResult } from "../ResponseResult";
import { DeprecatedModelWarningCard } from "../DeprecatedModelWarningCard";
import { Link } from "react-router-dom";
import { PromptEngineeringResponse } from "../PromptEngineeringResponse";

// @todo create usePromptEngineering hook
export default function PromptEngineeringMobileViewMain() {
  const dispatch = useDispatch();
  const { models, currentModel, sendPromptResponse } = useSelector(
    (state) => state.promptEngineering
  );
  const [prompt, setPrompt] = useState("");

  useEffect(() => {
    !currentModel && dispatch(updateCurrentModel(models[0]));
  }, []);

  if (!currentModel) return null;

  const { provider, owned_by, name: modelName } = currentModel;
  const { status, result, error, warning, response } = sendPromptResponse;

  const handlePromptSubmit = (e) => {
    e.preventDefault();
    console.log("handlePromptSubmit");

    dispatch(
      sendPrompt({
        prompt,
        model: currentModel,
        provider: provider || owned_by || "google",
      })
    );
  };

  return (
    <>
      <div className="container d-flex align-items-center justify-content-between my-2">
        <span className="me-2">
          <Link to="/">
            <i className="bi bi-arrow-left-circle"></i>
          </Link>
        </span>
        <span>
          <h2>{modelName || "Unknown"}</h2>
        </span>
      </div>
      <div className="container">
        <section className="mb-3">
          <label>Model Content</label>
          <div className="form-text text-break">
            {JSON.stringify(currentModel)}
          </div>
          {currentModel && <DeprecatedModelWarningCard model={currentModel} />}
        </section>
        <form className="mt-2 mb-3" onSubmit={handlePromptSubmit}>
          <textarea
            className="form-control"
            placeholder="What prompt do you want to send?"
            id="exampleFormControlTextarea1"
            rows="3"
            onChange={(e) => setPrompt(e.target.value)}
          ></textarea>
          <div className="text-center">
            <button type="submit" className="btn btn-primary mt-3">
              Send
            </button>
          </div>
        </form>
        {status === "pending" && (
          <div className="spinner-grow spinner-grow-sm" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        )}
        {(status === "failed" || status === "rejected") && (
          <div className="text-danger">{error.message}</div>
        )}
        {status === "success" && <ResponseResult result={result} />}
        {warning && (
          <div className="bd-callout bd-callout-warning bg-warning-subtle text-dark rounded px-2 py-1">
            {warning}
          </div>
        )}
        <PromptEngineeringResponse response={response} />
      </div>
    </>
  );
}
