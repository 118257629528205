import StorageList from "../../components/Storage/StorageList";
import SubscribeWidget from "../../components/forms/SubscribeWidget";

export default function FormAI() {
  const handleSessionStorageChange = (key) => (event) => {
    console.log("handleSessionStorageChange", key, event.target.value);
    sessionStorage.setItem(key, event.target.value);
  };

  const handleLocalStorageChange = (key) => (event) => {
    console.log("handleLocalStorageChange", key, event.target.value);
    localStorage.setItem(key, event.target.value);
  };

  return (
    <div className="container">
      <h2 className="my-3">Form AI</h2>
      <div className="row">
        <div className="col-12 col-sm-6 col-md-9">
          <form>
            <legend>
              <i className="bi-database" /> Indexed storage
            </legend>
            <div className="mb-3">
              <section>
                <label className="form-label">Embedding file</label>
                <div className="mb-3">
                  <input
                    type="file"
                    id="embeddingInput"
                    className="form-control"
                    placeholder="eg. https://somesite/mydata.jsonl"
                    aria-label="eg. https://somesite/mydata.jsonl"
                    aria-describedby="button-addon2"
                    accept=".pdf"
                  />
                  <div className="form-text">
                    File used for embedding. <code>PINECORN_API_KEY</code> is
                    required. PDF file only for now.
                  </div>
                </div>
                <div className="text-center">
                  <button
                    className="btn btn-outline-secondary"
                    type="button"
                    id="button-addon2"
                  >
                    Embedding
                  </button>
                </div>
              </section>
              <section>
                <label className="form-label">Fine tune</label>
                <div className="mb-3">
                  <input
                    type="file"
                    id="embeddingInput"
                    className="form-control"
                    placeholder="eg. https://somesite/mydata.jsonl"
                    aria-label="eg. https://somesite/mydata.jsonl"
                    aria-describedby="button-addon2"
                    accept=".csv"
                  />
                  <div className="form-text">
                    File used for embedding. <code>OPENAI_API_KEY</code> is
                    required. CSV file only for now.
                  </div>
                </div>
              </section>
              <div className="text-center">
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  id="button-addon2"
                >
                  Fine Tune
                </button>
              </div>
            </div>
          </form>
          <hr className="my-5" />
          <form>
            <legend>
              <i className="bi-key" /> Session storage
            </legend>
            <p>For your temploary using, just save your settings here.</p>
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                PINECORN_API_KEY
              </label>
              <input
                type="text"
                style={{
                  WebkitTextSecurity: "disc",
                }}
                className="form-control"
                placeholder="PINECORN_API_KEY"
                onChange={handleSessionStorageChange("PINECORN_API_KEY")}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                OPENAI_API_KEY
              </label>
              <input
                className="form-control"
                placeholder="OPENAI_API_KEY"
                type="text"
                onChange={handleSessionStorageChange("OPENAI_API_KEY")}
                style={{
                  WebkitTextSecurity: "disc",
                }}
              />
            </div>
          </form>
          <hr className="my-5" />
          <form>
            <legend>
              <i className="bi-key" /> Local storage
            </legend>
            <p>For your local long term using, save your settings here.</p>
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                PINECORN_API_KEY
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="PINECORN_API_KEY"
                onChange={handleLocalStorageChange("PINECORN_API_KEY")}
                style={{
                  WebkitTextSecurity: "disc",
                }}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                OPENAI_API_KEY
              </label>
              <input
                className="form-control"
                placeholder="OPENAI_API_KEY"
                type="text"
                onChange={handleLocalStorageChange("OPENAI_API_KEY")}
                style={{
                  WebkitTextSecurity: "disc",
                }}
              />
            </div>
          </form>
        </div>
        <div className="col-12 col-sm-6 col-md-3">
          <div className="mb-3">
            <StorageList />
          </div>
          {/* <SubscribeWidget /> */}
        </div>
      </div>
    </div>
  );
}
