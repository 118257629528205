import { useEffect, useState } from "react";
import OpenAIService from "../../../services/OpenAIService";
import openAIModels from "../../../data/openai-models.json";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";

const openAIService = new OpenAIService();

export default function ChatSuggestionCard({ chatlog, chatStatus }) {
  const [content, setContent] = useState("");
  const [prompt, setPrompt] = useState(
    "based on the latest assistant response, generate a list of items"
  );
  const [status, setStatus] = useState("idle");

  useEffect(() => {
    console.log("chatlog", chatlog);
    if (chatStatus !== "successed") {
      return;
    }

    setStatus("pending");
    openAIService
      .run({
        messages: [
          {
            role: "system",
            content: JSON.stringify(chatlog),
          },
          {
            role: "user",
            content: prompt,
          },
        ],
        model: openAIModels.data.find((model) => model.id === "gpt-4"),
      })
      .then((rsp) => {
        console.log("rsp", rsp);
        setContent(rsp.choices[0].message.content);
        setStatus("successed");
      })
      .catch((err) => {
        console.log("err", err);
        setStatus("failed");
      });
  }, [chatlog, chatStatus]);

  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">Chat Suggestion</h5>
        <p className="card-text">
          {content ? (
            <ReactMarkdown>{content}</ReactMarkdown>
          ) : (
            "This is a simple chat suggestion demo using AI."
          )}
        </p>
        {status === "pending" && <div className="text-end">processing...</div>}
        {content && (
          <div className="text-end">
            <button
              onClick={() => {
                alert("Mock: save successed");
              }}
              className="btn btn-outline-dark btn-sm"
            >
              add to your profile
            </button>
          </div>
        )}
        <label
          htmlFor="prompt"
          className="form-label mb-0 text-muted form-text"
        >
          Prompt
        </label>
        <textarea
          style={{ minHeight: 58 }}
          className="form-contorl w-100"
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
        />
      </div>
    </div>
  );
}
