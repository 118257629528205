import { Container } from "react-bootstrap";
import ModelsManagerHeader from "./components/ModelsManagerHeader";
import { Link, useNavigate } from "react-router-dom";
import LogoutButton from "../../components/Auth0/LogoutButton";
import { useAuth0 } from "@auth0/auth0-react";

export default function ModelsManagerSettings() {
  const { isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  if (!isAuthenticated) {
    console.log("isAuthenticated", isAuthenticated);
    navigate("/models-manager");
  }

  return isAuthenticated ? (
    <>
      <ModelsManagerHeader />
      <div className="row">
        <div className="col-3">
          <Container>
            <div className="list-group list-group-flush">
              <Link
                to="./api-keys"
                className="list-group-item list-group-item-action"
              >
                API Keys
              </Link>
              <div className="list-group-item">
                <LogoutButton />
              </div>
            </div>
          </Container>
        </div>
        <div className="col-9"></div>
      </div>
    </>
  ) : null;
}
