import { useAuth0 } from "@auth0/auth0-react";
import Header from "../../components/Header";
import ProfileHeader from "./ProfileHeader";
import SpinnerScreen from "../../components/Spinner/SpinnerScreen";
import SessionStorage from "../../components/Storage/SessionStorage";
import StorageList from "../../components/Storage/StorageList";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export default function Profile() {
  const navigate = useNavigate();
  const { user } = useAuth0();

  useEffect(() => {
    if (typeof user === "undefined") {
      console.log("undefined");
      navigate("/");
    }
  }, [user, navigate]);

  if (!user) return <SpinnerScreen />;
  return (
    <>
      <ProfileHeader />
      <div className="container">
        <div className="d-flex ">
          <div className="d-flex align-items-center">
            <img
              src={`https://api.dicebear.com/7.x/notionists/png?seed=${user.name}`}
              className="rounded-circle"
              width="50"
              height="50"
              alt={`avatar-${user.name}`}
            />
          </div>
          <div className="p-2">
            <h2 className="mb-0">{user.name}</h2>
            <p className="mb-0">{user.email}</p>
          </div>
        </div>
        <StorageList />
      </div>
    </>
  );
}
