import GooglePalmService from "../../../services/GooglePalmService";

export function addProviderMap(provider) {
  return (model) => ({
    provider,
    ...model,
  });
}

export function id2name(model) {
  return {
    ...model,
    name: model.id,
    displayName: model.id,
  };
}

export async function processGooglePrompt({ prompt, model }) {
  const { name } = model;
  switch (name) {
    case "models/chat-bison-001": {
      const rsp = await GooglePalmService.generateMessage({
        messages: [{ content: prompt }],
      });
      return rsp;
    }
    case "models/text-bison-001": {
      const rsp = await GooglePalmService.generateText({ text: prompt });
      return rsp;
    }
    case "models/embedding-gecko-001": {
      const rsp = await GooglePalmService.embedText({ text: prompt });
      return rsp;
    }
    default:
      console.error("unkown model");
      return Error("Unkown Model");
  }
}
