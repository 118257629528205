export default function SysteMessages() {
  return (
    <div className="container">
      <h1>System Message</h1>
      <p>
        System Message is a simple way to contrl OpenAI model to generate
        customized content.
      </p>
      <h2>Steps</h2>
      <p>First, create the system message item.</p>
      <div className="mb-3">
        <label htmlFor="floatingTextarea" className="form-label mb-1">
          System Message
        </label>
        <textarea
          className="form-control"
          id="floatingTextarea"
          placeholder="Leave a system message here."
        ></textarea>
      </div>
      <div className="text-center">
        <button type="submit" className="btn btn-outline-primary mb-3">
          Create
        </button>
      </div>
    </div>
  );
}
