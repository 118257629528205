import "bootstrap/dist/js/bootstrap.bundle.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./App.css";
import { Provider } from "react-redux";
import { store } from "./store";
import { RouterProvider } from "react-router";
import router from "./router.js";
import { Auth0Provider } from "@auth0/auth0-react";

export default function App() {
  console.log("window.location.origin", window.location.origin);
  return (
    <Auth0Provider
      domain="dev-wl9-2w1w.auth0.com"
      clientId="zTF1W1RrDnIFu1RmwfU9nx3Ie5CwyTit"
      authorizationParams={{
        redirect_uri: `${window.location.origin}/prompt-engineering`,
      }}
    >
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </Auth0Provider>
  );
}
