import { configureStore } from "@reduxjs/toolkit";
import FileSlice from "./FileSlice";
import TradinAISlice from "./TradinAISlice";
import PromptEngineeringSlice from "./PromptEngingeeringSlice/PromptEngineeringSlice";

export const store = configureStore({
  reducer: {
    file: FileSlice.reducer,
    tradinAI: TradinAISlice.reducer,
    promptEngineering: PromptEngineeringSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
