import SessionStorage from "./SessionStorage";
import LocalStorage from "./LocalStorage";
import IndexedDBSotrage from "./IndexedDBStorage";
import { useEffect, useState } from "react";
import HRNumbers from "human-readable-numbers";
import { Spinner } from "react-bootstrap";

export default function StorageList() {
  const [storageInfo, setStorageInfo] = useState();

  useEffect(() => {
    const interval = setInterval(() => {
      navigator.storage.estimate().then((estimate) => {
        setStorageInfo(estimate);
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  if (!storageInfo)
    return (
      <div className="d-flex justify-content-center m-5">
        <Spinner animation="border" />
      </div>
    );

  return (
    <>
      <div>
        <legend>
          <i className="bi-speedometer" /> Storage
        </legend>
        <IndexedDBSotrage />
        <LocalStorage />
        <SessionStorage />
        <div>
          Total: {HRNumbers.toHumanString(storageInfo?.usage)} /{" "}
          {HRNumbers.toHumanString(storageInfo?.quota)}
        </div>
      </div>
    </>
  );
}
