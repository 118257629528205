export default function sendPromptFullfilledReducer(state, action) {
  console.info("sendPrompt.fulfilled action", action);
  const { meta, payload } = action;
  const { error, candidates, message, embedding, filters, choices, warning } =
    payload;
  state.sendPromptResponse.response = action.payload;

  if (error || payload instanceof Error) {
    console.error("sendPrompt.fulfilled failed");
    state.sendPromptResponse.status = "failed";
    state.sendPromptResponse.error = {
      message: error?.message || message,
    };
    return;
  }

  if (embedding) {
    state.sendPromptResponse.status = "success";
    state.sendPromptResponse.result = embedding;
    return;
  }

  // openAI instrct model
  if (choices) {
    console.log("openAI instrct model");
    state.sendPromptResponse.status = "success";
    state.sendPromptResponse.warning = warning;
    state.sendPromptResponse.result =
      choices[0].message?.content || choices[0].text;
    return;
  }

  // google model filtered
  if (filters) {
    state.sendPromptResponse.status = "failed";
    state.sendPromptResponse.error = {
      message: `rejected by filters: ${JSON.stringify(filters)}`,
    };
    return;
  }

  // google model success
  if (candidates) {
    const { content, output } = candidates[0];

    state.sendPromptResponse.status = "success";
    state.sendPromptResponse.result = content || output || "no result";
    return;
  }

  if (meta.arg.provider === "google" && !candidates) {
    state.sendPromptResponse.status = "failed";
    state.sendPromptResponse.error = {
      message: "no candidates return",
    };
    if (filters) {
      console.warn("sendPromptResponse failed due to filters", filters);
    }
    return;
  }

  state.sendPromptResponse.status = "failed";
  state.sendPromptResponse.error = {
    message: "undexpected return",
  };
}
