const deprecatedmodels = [
  // First-generation text embedding models
  {
    shutdownDate: "2024-01-04",
    model: "text-similarity-ada-001",
    price: "$0.004 / 1K tokens",
    recommendedReplacement: "text-embedding-ada-002",
  },
  {
    shutdownDate: "2024-01-04",
    model: "text-search-ada-doc-001",
    price: "$0.004 / 1K tokens",
    recommendedReplacement: "text-embedding-ada-002",
  },
  {
    shutdownDate: "2024-01-04",
    model: "text-search-ada-query-001",
    price: "$0.004 / 1K tokens",
    recommendedReplacement: "text-embedding-ada-002",
  },
  {
    shutdownDate: "2024-01-04",
    model: "code-search-ada-code-001",
    price: "$0.004 / 1K tokens",
    recommendedReplacement: "text-embedding-ada-002",
  },
  {
    shutdownDate: "2024-01-04",
    model: "code-search-ada-text-001",
    price: "$0.004 / 1K tokens",
    recommendedReplacement: "text-embedding-ada-002",
  },
  {
    shutdownDate: "2024-01-04",
    model: "text-similarity-babbage-001",
    price: "$0.005 / 1K tokens",
    recommendedReplacement: "text-embedding-ada-002",
  },
  {
    shutdownDate: "2024-01-04",
    model: "text-search-babbage-doc-001",
    price: "$0.005 / 1K tokens",
    recommendedReplacement: "text-embedding-ada-002",
  },
  {
    shutdownDate: "2024-01-04",
    model: "text-search-babbage-query-001",
    price: "$0.005 / 1K tokens",
    recommendedReplacement: "text-embedding-ada-002",
  },
  {
    shutdownDate: "2024-01-04",
    model: "code-search-babbage-code-001",
    price: "$0.005 / 1K tokens",
    recommendedReplacement: "text-embedding-ada-002",
  },
  {
    shutdownDate: "2024-01-04",
    model: "code-search-babbage-text-001",
    price: "$0.005 / 1K tokens",
    recommendedReplacement: "text-embedding-ada-002",
  },
  {
    shutdownDate: "2024-01-04",
    model: "text-similarity-curie-001",
    price: "$0.020 / 1K tokens",
    recommendedReplacement: "text-embedding-ada-002",
  },
  {
    shutdownDate: "2024-01-04",
    model: "text-search-curie-doc-001",
    price: "$0.020 / 1K tokens",
    recommendedReplacement: "text-embedding-ada-002",
  },
  {
    shutdownDate: "2024-01-04",
    model: "text-search-curie-query-001",
    price: "$0.020 / 1K tokens",
    recommendedReplacement: "text-embedding-ada-002",
  },
  {
    shutdownDate: "2024-01-04",
    model: "text-similarity-davinci-001",
    price: "$0.200 / 1K tokens",
    recommendedReplacement: "text-embedding-ada-002",
  },
  {
    shutdownDate: "2024-01-04",
    model: "text-search-davinci-doc-001",
    price: "$0.200 / 1K tokens",
    recommendedReplacement: "text-embedding-ada-002",
  },
  {
    shutdownDate: "2024-01-04",
    model: "text-search-davinci-query-001",
    price: "$0.200 / 1K tokens",
    recommendedReplacement: "text-embedding-ada-002",
  },
  {
    model: "text-ada-001",
    price: "$0.0004 / 1K tokens",
    shutdownDate: "2024-01-04",
    recommendedReplacement: "gpt-3.5-turbo-instruct",
  },
  {
    model: "text-babbage-001",
    price: "$0.0005 / 1K tokens",
    shutdownDate: "2024-01-04",
    recommendedReplacement: "gpt-3.5-turbo-instruct",
  },
  {
    model: "text-curie-001",
    price: "$0.0020 / 1K tokens",
    shutdownDate: "2024-01-04",
    recommendedReplacement: "gpt-3.5-turbo-instruct",
  },
  {
    model: "text-davinci-001",
    price: "$0.0200 / 1K tokens",
    shutdownDate: "2024-01-04",
    recommendedReplacement: "gpt-3.5-turbo-instruct",
  },
  {
    model: "text-davinci-002",
    price: "$0.0200 / 1K tokens",
    shutdownDate: "2024-01-04",
    recommendedReplacement: "gpt-3.5-turbo-instruct",
  },
  {
    model: "text-davinci-003",
    price: "$0.0200 / 1K tokens",
    shutdownDate: "2024-01-04",
    recommendedReplacement: "gpt-3.5-turbo-instruct",
  },
  // Fine-tuning GPT models
  {
    shutdownDate: "2024-01-04",
    model: "ada",
    price: "$0.0004 / 1K tokens",
    recommendedReplacement: "babbage-002",
  },
  {
    shutdownDate: "2024-01-04",
    model: "babbage",
    price: "$0.0005 / 1K tokens",
    recommendedReplacement: "babbage-002",
  },
  {
    shutdownDate: "2024-01-04",
    model: "curie",
    price: "$0.0020 / 1K tokens",
    recommendedReplacement: "davinci-002",
  },
  {
    shutdownDate: "2024-01-04",
    model: "davinci",
    price: "$0.0200 / 1K tokens",
    recommendedReplacement: "davinci-002",
  },
  {
    shutdownDate: "2024-01-04",
    model: "codeDavinci002",
    price: "free to researchers",
    recommendedReplacement: "gpt-3.5-turbo-base",
  },
  // Edit models
  {
    shutdownDate: "2024-01-04",
    model: "text-davinci-edit-001",
    recommendedReplacement: "gpt-4",
  },
  {
    shutdownDate: "2024-01-04",
    model: "code-davinci-edit-001",
    recommendedReplacement: "gpt-4",
  },
  // Updated chat models
  {
    shutdownDate: "at earliest 2024-06-13",
    model: "gpt-3.5-turbo-0301",
    recommendedReplacement: "gpt-3.5-turbo-0613",
  },
  {
    shutdownDate: "at earliest 2024-06-13",
    model: "gpt-4-0314",
    recommendedReplacement: "gpt-4-0613",
  },
  {
    shutdownDate: "at earliest 2024-06-13",
    model: "gpt-4-32k-0314",
    recommendedReplacement: "gpt-4-32k-0613",
  },
  // 2023-03-20: Codex models
  {
    shutdownDate: "2023-03-23",
    model: "code-davinci-002",
    recommendedReplacement: "gpt-4",
  },
  {
    shutdownDate: "2023-03-23",
    model: "code-davinci-001",
    recommendedReplacement: "gpt-4",
  },
  {
    shutdownDate: "2023-03-23",
    model: "code-cushman-002",
    recommendedReplacement: "gpt-4",
  },
  {
    shutdownDate: "2023-03-23",
    model: "code-cushman-001",
    recommendedReplacement: "gpt-4",
  },
  // 2022-06-03: Legacy endpoints
  {
    shutdownDate: "2022-12-03",
    system: "/v1/engines",
    recommendedReplacement: "/v1/models",
  },
  {
    shutdownDate: "2022-12-03",
    system: "/v1/search",
  },
  {
    shutdownDate: "2022-12-03",
    system: "/v1/classifications",
  },
  {
    shutdownDate: "2022-12-03",
    system: "/v1/answers",
  },
];

export default deprecatedmodels;
