import { useAuth0 } from "@auth0/auth0-react";

export default function ProfileDropdown() {
  const { logout, user } = useAuth0();
  console.log("user", user);
  return (
    <ul className="dropdown-menu">
      <a className="dropdown-item" href="#/profile">
        {user.name}
      </a>
      <li>
        <a className="dropdown-item" href="#/prompt-engineering">
          Prompt Engineering
        </a>
      </li>
      <li>
        <hr className="dropdown-divider" />
      </li>
      <li>
        <a className="dropdown-item" href="#/models-manager">
          Models Manager
        </a>
      </li>
      <li>
        <a className="dropdown-item" href="#/pricing">
          Pricing
        </a>
      </li>
      <li>
        <a className="dropdown-item" href="#/models-manager/settings">
          Settings
        </a>
      </li>
      <li>
        <hr className="dropdown-divider" />
      </li>
      <li>
        <button
          className="dropdown-item text-danger"
          onClick={() =>
            logout({
              logoutParams: {
                returnTo: window.location.origin + "/prompt-engineering",
              },
            })
          }
        >
          Logout
        </button>
      </li>
    </ul>
  );
}
