import { useDispatch } from "react-redux";
import { updateCurrentModel } from "../../../store/PromptEngingeeringSlice/PromptEngineeringSlice";
import useDeprecatedModel from "../hooks/useDeprecatedModel";
import { useNavigate, useSearchParams } from "react-router-dom";
import { isMobile } from "react-device-detect";

export function ModelListItem(model) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const deprecatedModel = useDeprecatedModel(model);
  const dispatch = useDispatch();
  const { displayName } = model;

  const handleModelListItemClick = () => {
    console.log("handleModelListItemClick", isMobile);
    dispatch(updateCurrentModel(model));
    console.log("isMobile", isMobile);
    if (isMobile) {
      navigate("/prompt-engineering/mobile-view/main");
    }
  };

  if (searchParams.get("pro") === null && deprecatedModel) return null;

  return (
    <button
      type="button"
      className="list-group-item list-group-item-action"
      onClick={handleModelListItemClick}
    >
      {displayName}{" "}
      {deprecatedModel && <strong className="text-warning">D</strong>}
    </button>
  );
}
