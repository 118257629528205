export default function OpenAIModelsManager() {
  return (
    <>
      <h2>Open AI</h2>
      <h3>Fine Tuning</h3>
      <form>
        <div className="mb-3">
          <label htmlFor="exampleInputEmail1" className="form-label">
            File
          </label>
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="eg. https://somesite/mydata.jsonl"
              aria-label="eg. https://somesite/mydata.jsonl"
              aria-describedby="button-addon2"
            />
            <button
              className="btn btn-outline-secondary"
              type="button"
              id="button-addon2"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </>
  );
}
