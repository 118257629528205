import { ReactMarkdown } from "react-markdown/lib/react-markdown";

export default function BasicChatlog({ chatlog }) {
  return (
    <div className="chatlog">
      {chatlog.map((chat, index) => (
        <div key={index} className="chatlog__item d-flex ">
          <div className="chatlog__item__avatar py-2">
            <img
              src={`https://api.dicebear.com/7.x/notionists/png?seed=${chat.role}`}
              className="rounded-circle"
              width="50"
              height="50"
              alt={`avatar-${chat.role}`}
            />
          </div>
          <div className="chatlog__item__message ml-auto p-2">
            <div className="chatlog__item__message__name text-muted fw-light">
              {chat.role}
            </div>
            <div className="chatlog__item__message__content">
              <ReactMarkdown>{chat.content}</ReactMarkdown>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
