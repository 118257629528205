import { BrowserView, MobileView } from "react-device-detect";
import PromptEngineering from "./PromptEngineering";
import PromptEngineeringMobileViewIndex from "./Components/MobileView/PromptEngineeringMobileViewIndex";

export default function PromptEngineeringIndex() {
  return (
    <>
      <BrowserView>
        <PromptEngineering />
      </BrowserView>
      <MobileView>
        <PromptEngineeringMobileViewIndex />
      </MobileView>
    </>
  );
}
