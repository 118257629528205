import HRNumbers from "human-readable-numbers";

export default function SessionStorage() {
  var limit = 1024 * 1024 * 5; // 5 MB
  var remSpace = unescape(
    encodeURIComponent(JSON.stringify(sessionStorage))
  ).length;

  return (
    <div>
      <label
        style={{ cursor: "help" }}
        title="Session storage will clean up when you close your browser."
      >
        Session
      </label>
      : {HRNumbers.toHumanString(remSpace)} / {HRNumbers.toHumanString(limit)}
    </div>
  );
}
