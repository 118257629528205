import { useState } from "react";
import PromptEngineeringHeader from "../PromptEngineering/Components/PromptEngineeringHeader";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";

async function fetchOpenAI({
  content,
  AISettings,
  step = 0,
  setStep,
  result = "",
  results = [],
  setResults,
}) {
  if (step >= AISettings.length) {
    console.log("result", result);
    setResults(results);
    return;
  }

  const body = JSON.stringify({
    messages: [
      { role: "system", content: AISettings[step].systemMessage },
      { role: "user", content },
    ],
    model: {
      id: "gpt-4",
      object: "model",
      created: 1687882411,
      owned_by: "openai",
      permission: [
        {
          id: "modelperm-tsET7VSHRhiQHP5Qz6TU1FBb",
          object: "model_permission",
          created: 1693330062,
          allow_create_engine: false,
          allow_sampling: false,
          allow_logprobs: false,
          allow_search_indices: false,
          allow_view: false,
          allow_fine_tuning: false,
          organization: "*",
          group: null,
          is_blocking: false,
        },
      ],
      root: "gpt-4",
      parent: null,
      name: "gpt-4",
      displayName: "gpt-4",
    },
  });

  fetch("https://finai-server.deno.dev/openai/v1/chat/completions", {
    headers: {
      accept: "*/*",
      "accept-language": "en-US,en;q=0.9,zh-CN;q=0.8,zh;q=0.7",
      "cache-control": "no-cache",
      "content-type": "text/plain;charset=UTF-8",
      pragma: "no-cache",
      "sec-ch-ua":
        '"Chromium";v="118", "Google Chrome";v="118", "Not=A?Brand";v="99"',
      "sec-ch-ua-mobile": "?0",
      "sec-ch-ua-platform": '"macOS"',
      "sec-fetch-dest": "empty",
      "sec-fetch-mode": "cors",
      "sec-fetch-site": "cross-site",
    },
    referrer: "http://localhost:3000/",
    referrerPolicy: "strict-origin-when-cross-origin",
    body,
    method: "POST",
    mode: "cors",
    credentials: "omit",
  })
    .then((rsp) => rsp.json())
    .then((rsp) => {
      console.log(rsp);
      const { choices } = rsp;
      const { content } = choices[0].message;
      results = [...results, content];
      step = step + 1;
      setResults(results);
      setStep(step);
      fetchOpenAI({
        content,
        AISettings,
        step,
        setStep,
        result: content,
        results,
        setResults,
      });
    })
    .catch((err) => {
      console.log(err);

      setResults([...results, "Error"]);
    });
}

export default function Waterflow() {
  console.log("Waterflow");
  const [prompt, setPrompt] = useState("Order a pizza for me.");
  const [AISettings, setAISettings] = useState([
    {
      systemMessage: "You are a helpful assistant",
    },
    {
      systemMessage:
        "Tell the customer that you are a pizza store assistant and ask the customer what kind of pizza they want to order.",
    },
  ]);
  const [results, setResults] = useState([]);
  const [step, setStep] = useState();

  const handleAISettingsChange = (idx) => (e) => {
    AISettings[idx].systemMessage = e.target.value;
    setAISettings([...AISettings]);
  };

  const handleAISettingsDelete = (idx) => () => {
    AISettings.splice(idx, 1);
    setAISettings([...AISettings]);
  };

  const handleAISettingsInsertClick = () => {
    setAISettings([...AISettings, { systemMessage: "" }]);
  };

  const handleSendClick = () => {
    fetchOpenAI({
      content: `create question that ask ${prompt}`,
      AISettings,
      setResults,
      step,
      setStep,
    });
  };

  const handleResetClick = () => {
    setStep(undefined);
  };

  return (
    <div>
      <PromptEngineeringHeader />
      <div className="container">
        <div className="mb-3">
          <textarea
            className="d-block mb-3 rounded p-2 w-100"
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
          />
          <div className="text-center">
            <button className="btn btn-dark" onClick={handleSendClick}>
              Send
            </button>
            <button
              className="btn btn-outline-dark ms-2"
              onClick={handleResetClick}
            >
              Reset
            </button>
          </div>
        </div>
        {AISettings.map((aiSetting, idx) => (
          <div key={idx}>
            <div className="form-label">AI No. {idx + 1}</div>
            <textarea
              className="d-block mb-3 rounded p-2 w-100"
              onChange={handleAISettingsChange(idx)}
              value={aiSetting.systemMessage}
              disabled={idx < step}
            />
            <ReactMarkdown>{results[idx]}</ReactMarkdown>
            <div className="text-end">
              <button
                className="btn btn-outline-danger btn-sm mb-3"
                onClick={handleAISettingsDelete(idx)}
                disabled={idx < step}
              >
                Delete
              </button>
            </div>
          </div>
        ))}
        <div className="text-center">
          <button
            className="text btn-outline-dark btn btn-sm"
            onClick={handleAISettingsInsertClick}
          >
            Insert a New Step
          </button>
        </div>
      </div>
    </div>
  );
}
