import { useAuth0 } from "@auth0/auth0-react";
import Header from "../../components/Header";
import ProfileDropdown from "../../components/Nav/ProfileDropdown";

export default function ProfileHeader() {
  const { user, logout, loginWithRedirect } = useAuth0();
  return (
    <Header>
      <h1>Profile</h1>
      <div>
        <span
          className="btn btn-sm btn-dark rounded-circle"
          title="settings"
          style={{ padding: "0.25rem" }}
          onClick={() => {
            !user && loginWithRedirect();
          }}
        >
          {user ? (
            <>
              <img
                src={`https://api.dicebear.com/7.x/notionists/jpg?seed=${user.name}`}
                alt={user.name}
                className="rounded-circle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style={{ height: 24, width: 24 }}
              />
              <ProfileDropdown />
            </>
          ) : (
            <i
              className="bi bi-person-circle"
              style={{
                lineHeight: "24px",
                height: 24,
                width: 24,
                display: "inline-block",
              }}
            ></i>
          )}
        </span>
      </div>
    </Header>
  );
}
