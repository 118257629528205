import { createHashRouter } from "react-router-dom";
import PromptEngineering from "./pages/PromptEngineering";
import { useEffect } from "react";
import PromptEngineeringMobileViewMain from "./pages/PromptEngineering/Components/MobileView/PromptEngineeringMobileViewMain";
import ModelsManager from "./pages/ModelsManager";
import ModelsManagerSettings from "./pages/ModelsManager/ModelsManagerSettings";
import Pricing from "./pages/Pricing";
import Waterflow from "./pages/Waterfall/Waterflow";
import Profile from "./pages/Profile/Profile";
import FormAI from "./pages/Form/FormAI";
import SysteMessages from "./pages/OpenAI/SystemMessages/SystemMessages";
import ChatSuggestion from "./pages/Chat/ChatSuggestion/ChatSuggestion";

const router = createHashRouter([
  {
    path: "/",
    element: <PromptEngineering />,
  },
  {
    path: "/form-ai",
    element: <FormAI />,
  },
  {
    path: "/openai/chat-suggestion",
    element: <ChatSuggestion />,
  },
  {
    path: "/openai/system-messages",
    element: <SysteMessages />,
  },
  {
    path: "/models-manager",
    element: <ModelsManager />,
    exact: true,
  },
  {
    path: "/models-manager/settings",
    element: <ModelsManagerSettings />,
  },
  {
    path: "/pricing",
    element: <Pricing />,
  },
  {
    path: "/profile",
    element: <Profile />,
  },
  {
    path: "/prompt-engineering",
    element: <PromptEngineering />,
    exact: true,
  },
  {
    path: "/prompt-engineering/mobile-view/main",
    element: <PromptEngineeringMobileViewMain />,
    exact: true,
  },
  {
    path: "/prompt-engineering/openai/deprecation",
    element: <Redirect href="https://platform.openai.com/docs/deprecations" />,
  },
  {
    path: "/waterflow",
    element: <Waterflow />,
  },
]);

function Redirect({ href }) {
  useEffect(() => {
    window.location.href = href;
  }, []);
  return null;
}

export default router;
