export default function BasicChatInput({ value, onChange }) {
  return (
    <div className="w-100">
      <input
        autoFocus
        type="text"
        value={value}
        onChange={onChange}
        className="form-control"
        placeholder="Type your message here."
      />
    </div>
  );
}
