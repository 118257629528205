import { Link } from "react-router-dom";
import Header from "../../../components/Header";
import { useAuth0 } from "@auth0/auth0-react";

export default function ModelsManagerHeader() {
  const { user, isAuthenticated } = useAuth0();

  console.log("user", user);

  return (
    <Header>
      <h1>Models Manager</h1>
      <div>
        <a href="#/" className="btn btn-sm btn-dark" title="home">
          <i className="bi bi-layout-text-window-reverse"></i>
        </a>
        <Link
          to="/models-manager/settings"
          className="btn btn-sm btn-dark rounded-circle"
          style={{ padding: "0.25rem" }}
        >
          <img
            src={user.picture}
            alt={user.name}
            className="rounded-circle"
            style={{ height: 24, width: 24 }}
          />
        </Link>
      </div>
    </Header>
  );
}
