import { Container } from "react-bootstrap";
import Header from "../../components/Header";
import OpenAIModelsManager from "./OpenAIModelsManager/OpenAIModelsManager";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import ModelsManagerHeader from "./components/ModelsManagerHeader";

export default function ModelsManager() {
  return (
    <>
      <ModelsManagerHeader />
      <Container>
        <OpenAIModelsManager />
      </Container>
    </>
  );
}
