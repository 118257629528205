import { useDispatch, useSelector } from "react-redux";
import { ModelListItem } from "../ModelListItem";
import { Fragment, useState } from "react";
import { sendPrompt } from "../../../../store/PromptEngingeeringSlice/PromptEngineeringSlice";
import { useSearchParams } from "react-router-dom";
import { getDeprcatedModel } from "../../hooks/useDeprecatedModel";

export default function PromptEngineeringMobileView() {
  const [searchParams] = useSearchParams();
  const { models, currentModel } = useSelector(
    (state) => state.promptEngineering
  );

  const dispatch = useDispatch();
  const [prompt, setPrompt] = useState("");

  const handlePromptSubmit = (e) => {
    e.preventDefault();
    console.log("handlePromptSubmit");
    dispatch(
      sendPrompt({
        prompt,
        model: currentModel,
        provider: currentModel.owned_by || "google",
      })
    );
  };

  const handleTextareaKeyDown = (e) => {
    if (e.key === "Enter" && (e.ctrlKey || e.metaKey)) {
      handlePromptSubmit(e);
    }
  };

  console.log(
    "models",
    models.filter((model) => {
      const deprecatedModel = getDeprcatedModel(model);
      if (searchParams.get("pro") === null && deprecatedModel) return true;
    })
  );

  return (
    <>
      <header>
        <h1 className="bg-dark text-light p-2 m-0">Prompt Engineering</h1>
      </header>
      <div className="container">
        <form className="mt-2 mb-3" onSubmit={handlePromptSubmit}>
          <textarea
            className="form-control"
            placeholder="What prompt do you want to send?"
            id="exampleFormControlTextarea1"
            rows="3"
            onChange={(e) => setPrompt(e.target.value)}
            onKeyDown={handleTextareaKeyDown}
          ></textarea>
          <div className="text-center">
            <button type="submit" className="btn btn-primary mt-3">
              Send
            </button>
          </div>
        </form>
        <div
          style={{ maxHeight: "30vh", minHeight: "200px", overflowY: "scroll" }}
        >
          <div className="list-group list-group-flush">
            {models
              .filter((model) => {
                const deprecatedModel = getDeprcatedModel(model);
                if (searchParams.get("pro") === null && deprecatedModel)
                  return true;
              })
              .map((model, idx) => (
                <div key={idx} className="container-fluid form-check">
                  <input type="checkbox" className="form-check-input" />
                  <label className="form-check-label">{model.name}</label>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}
