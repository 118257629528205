import { Link } from "react-router-dom";
import useDeprecatedModel from "../hooks/useDeprecatedModel";

// @todo click the model name should switch to the suggeeted model
export function DeprecatedModelWarningCard({ model }) {
  const deprecatedModel = useDeprecatedModel(model);
  if (!deprecatedModel) return null;
  const {
    model: modelName,
    shutdownDate,
    recommendedReplacement,
  } = deprecatedModel;
  return (
    <div className="card text-dark bg-warning mb-3">
      <div className="card-header">Deprecated Model</div>
      <div className="card-body">
        <p className="card-text">
          The model {modelName} will be deprecated on{" "}
          <Link
            to="openai/deprecation"
            target="_blank"
            className="text-dark text-decoration-none"
            style={{ borderBottom: "1px dotted" }}
          >
            {shutdownDate}
          </Link>
          . Recommand to use model <strong>{recommendedReplacement}</strong>.
        </p>
      </div>
    </div>
  );
}
