import googleModels from "../../data/google-models.json";
import openAIModels from "../../data/openai-models.json";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addProviderMap, id2name, processGooglePrompt } from "./utils";
import OpenAIService from "../../services/OpenAIService";
import sendPromptFullfilledReducer from "./PromptEngineeringReducers/sendPromptFulfilledReducer";

const getOpenAIPromptMesssages = ({ prompt, messages, model }) => {
  switch (model.name) {
    case "gpt-4":
      return {
        messages: [{ role: "user", content: prompt }],
      };
    case "davinci:ft-personal-2023-07-20-08-29-26":
    case "davinci:ft-personal:tradein-001-2023-07-20-10-40-21":
    case "davinci:ft-personal:icp042-8517-2023-07-22-12-04-33":
    case "davinci:ft-personal:tradein-003-2023-07-20-19-29-53":
    case "babbage-search-query":
    case "curie-instruct-beta":
    case "ada-code-search-text":
    case "ada-similarity":
    case "davinci-002":
    case "davinci-instruct-beta":
      return {
        prompt,
      };
    default:
      return {
        messages: [{ role: "user", content: prompt }],
      };
  }
};

export const sendPrompt = createAsyncThunk(
  "promptEngineering/sendPrompt",
  async (payload) => {
    console.log("promptEngineering/sendPrompt called");
    console.log("promptEngineering/sendPrompt called payload", payload);

    const { prompt, messages, model } = payload;
    const { provider, owned_by } = model;
    const providerOwner = owned_by || provider;
    console.log("providerOwner", providerOwner);
    switch (providerOwner) {
      case "google": {
        const rsp = await processGooglePrompt({ prompt, model });
        return {
          ...rsp,
          model,
        };
      }
      case "openai-dev": // fall through
      case "user-om0d6z9vsogur7gkynov5z5y": // fall through
      case "openai": {
        const openAIService = new OpenAIService();
        const { prompt: openAIPrompt, messages: openAIMessages } =
          getOpenAIPromptMesssages({
            prompt,
            messages,
            model,
          });
        const rsp = await openAIService.run({
          prompt: openAIPrompt,
          messages: openAIMessages,
          model,
        });
        return {
          ...rsp,
          model,
        };
      }
      default:
        console.warn("unkown provider, fall by OpenAI");
        const openAIService = new OpenAIService();
        const rsp = await openAIService.run({ prompt, model });
        return {
          ...rsp,
          model,
        };
    }
  }
);

const PromptEngineeringSlice = createSlice({
  name: "promptEngineering",
  initialState: {
    sendPromptResponse: {
      status: "idle",
      result: null,
      error: null,
      log: [],
    },
    currentModel: null,
    models: [
      ...googleModels.models.map(addProviderMap("google")),
      ...openAIModels.data.map(id2name),
    ],
  },
  reducers: {
    updateCurrentModel: (state, action) => {
      state.currentModel = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sendPrompt.pending, (state) => {
      state.sendPromptResponse.status = "pending";
      state.sendPromptResponse.warning = "";
      state.sendPromptResponse.response = null;
    });

    builder.addCase(sendPrompt.fulfilled, sendPromptFullfilledReducer);

    builder.addCase(sendPrompt.rejected, (state, action) => {
      console.info("sendPrompt.rejected action", action);
      state.sendPromptResponse.status = "rejected";
      state.sendPromptResponse.error = action.error;
    });
  },
});

export const { updateCurrentModel } = PromptEngineeringSlice.actions;

export default PromptEngineeringSlice;
